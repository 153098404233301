import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigInterface } from '../../match-visualization/models';
import { ParamMap } from '@angular/router';
import { routeParamsConfig } from '../../configs/route-params-config';
import { first, map } from 'rxjs/operators';
import { MatchConfigErrorEnum } from './match-config-error.enum';
import { LoggerService } from '../logger/logger.service';
import { Sports } from '../../configs';
import { Provider } from '../../models/proto-models/enums/provider.enum';
import { IframeCommunicationService } from '../iframe-communication/iframe-communication.service';

@Injectable({
    providedIn: 'root',
})
export class MatchConfigService {
    private config$ = new BehaviorSubject<ConfigInterface>({
        score: true,
        statistic: false,
        sound: false,
        team_names: true,
        timer: true,
        token: '',
        lang: 'eng', // default language
        matchID: '',
        isLinked: false,
        provider: Provider.animation,
        rtl: false,
        partner: '1',
        teamNames: [],
    });

    private _sport: Sports;
    private configObs$ = this.config$.asObservable();

    constructor(private loggerService: LoggerService, private iframeCommunicationService: IframeCommunicationService) {
    }

    get sport(): Sports {
        return this._sport;
    }

    set sport(value: Sports) {
        this._sport = value;
    }

    public setConfig(data: any, matchId: string): void {
        const currentConfig = this.config$.getValue();
        const newConfig: ConfigInterface = {
            score: this.checkForValueCondition(data.score) && data.score.toString() === 'false' ? false
                : (this.checkForValueCondition(data.score) && data.score.toString() === 'true' ? true : currentConfig.score),
            statistic: this.checkForValueCondition(data.statistic) && data.statistic.toString() === 'false' ? false
                : (this.checkForValueCondition(data.statistic) && data.statistic.toString() === 'true' ? true : currentConfig.statistic),
            sound: this.checkForValueCondition(data.sound) && data.sound.toString() === 'false' ? false
                : (this.checkForValueCondition(data.sound) && data.sound.toString() === 'true' ? true : currentConfig.sound),
            team_names: this.checkForValueCondition(data.team_names) && data.team_names.toString() === 'false' ? false
                : (this.checkForValueCondition(data.team_names) && data.team_names.toString() === 'true' ? true : currentConfig.team_names),
            timer: this.checkForValueCondition(data.timer) && data.timer.toString() === 'false' ? false
                : (this.checkForValueCondition(data.timer) && data.timer.toString() === 'true' ? true : currentConfig.timer),
            lang: data.lang || currentConfig.lang,
            token: data.token,
            matchID: matchId,
            provider: data.source || currentConfig.provider,
            isLinked: this.checkForValueCondition(data.isLinked) && data.isLinked.toString() === 'true',
            rtl: this.checkForValueCondition(data.rtl) && data.rtl.toString() === 'true',
            partner: data.partner,
            teamNames: data.teamNames ? this.parseTeamNames(data.teamNames) : [],
        };
        this.config$.next(newConfig);
    }

    public getCurrentConfig(): ConfigInterface {
        return this.config$.getValue();
    }

    public getConfig$(): Observable<ConfigInterface> {
        return this.configObs$;
    }

    public getAnimationParametersViaURL(params: ParamMap, queryParams: ParamMap): void {
        const matchId = params.get(routeParamsConfig.matchID);

        const config = queryParams.keys.reduce((acc, key) => {
            acc[key] = queryParams.get(key);

            return acc;
        }, {});

        this.setConfig(config, matchId);
    }

    public createAnimationParametersViaPostMessage(): Observable<boolean> {
        this.iframeCommunicationService.sendMessageToParent('ready');

        return this.iframeCommunicationService.getParentMessages$().pipe(
            first((res: MessageEvent) => {
                    if (!res) {
                        return false;
                    }

                    const isDataOk = this.checkForValueCondition(res.data)
                        && this.checkForValueCondition(res.data.id)
                        && this.checkForValueCondition(res.data.token);

                    this.loggerService.logData(res.data, 'Data from postMessage');

                    if (!isDataOk) {
                        this.iframeCommunicationService.sendMessageToParent({ error: MatchConfigErrorEnum.WRONG_PARAMS });
                    }

                    return isDataOk;
                },
            ),
            map((r: MessageEvent) => {
                const config = r.data;
                const matchId = r.data.id;
                this.setConfig(config, matchId);

                return false;
            }),
        );
    }

    private checkForValueCondition(value: any): boolean {
        return value !== null && typeof value !== 'undefined';
    }

    private parseTeamNames(value: string | [string, string]): string[] {
        return typeof value === 'string' ? JSON.parse(decodeURIComponent(value)) : value;
    }
}
