export enum GameEventType {
    // Football
    DANGEROUS_ATTACK = 0,
    ATTACK = 1,
    SAFE_POSSESSION = 2,
    GOAL = 3,
    CORNER = 4,
    YELLOW_CARD = 5,
    RED_CARD = 6,
    GOAL_KICK = 7,
    PENALTY = 8,
    DANGEROUS_FREE_KICK_ZONE = 9,
    FREE_KICK_ZONE = 10,
    SHOT_ON_TARGET = 11,
    SHOT_OFF_TARGET = 12,
    SUBSTITUTION = 13,
    KICK_OFF = 14,
    END_OF_HALF = 15,
    START_OF_HALF2 = 16,
    END_OF_FULL_TIME = 17,
    EXTRA_TIME1 = 18,
    END_OF_EXTRA_TIME1 = 19,
    EXTRA_TIME2 = 20,
    END_OF_EXTRA_TIME = 21,
    PENALTY_SHOOT_OUT = 22,
    PENALTY_MISSED = 23,
    THROW_ZONE = 24,
    INJURY = 25,
    INJURY_TIME = 26,
    MATCH_INFO = 27,
    OFFSIDE = 28,
    SUBSTITUTION_ENHANCED = 29,
    TAKE_ON_DANGEROUS_ATTACK = 30,
    TAKE_ON_ATTACK = 31,
    TAKE_ON_SAFE_POSSESSION = 32,
    REPLAY_POSSESSION = 33,
    REPLAY_PENALTY = 34,
    REPLAY_GOAL = 35,
    REPLAY_TRIGGER = 36,
    VIDEO_REFEREE_IN_PROGRESS = 37,
    VIDEO_REFEREE_REVIEWING_RED_CARD = 38,
    VIDEO_REFEREE_REVIEWING_GOAL = 39,
    VIDEO_REFEREE_REVIEWING_PENALTY = 40,
    SHOOT_OUT_SCORE = 41,
    SHOOT_OUT_MISS = 42,
    SHOOT_OUT_TO_TAKE = 43,
    BLOCKED_SHOT = 320,
    GOAL_ABANDONED = 321,
    RED_CARD_CANCELLED = 322,
    YELLOW_CARD_CANCELLED = 323,
    STANDBY_FOR_KICK_OFF = 324,
    POINT_INVESTIGATION = 325,

    // Basketball
    TWO_POINTS = 44,
    THREE_POINTS = 45,
    IN_POSSESSION = 46,
    FREE_THROW = 47,
    FREE_THROW_SCORED = 48,
    FREE_THROW_MISSED = 49,
    TIME_OUT = 50,
    END_OF_QUARTER = 51,
    END_OF_MATCH = 52,
    OVER_TIME = 53,
    FOUL = 54,
    START_OF_QUARTER1 = 55,
    START_OF_QUARTER2 = 56,
    START_OF_QUARTER3 = 57,
    START_OF_QUARTER4 = 58,
    START_OF_HALF1 = 59,
    TWO_POINTS_SHOT_MISSED = 60,
    THREE_POINTS_SHOT_MISSED = 61,
    TV_TIME_OUT_OVER = 326,
    POINT_MISSED = 327,
    FOUL_CANCELLED = 351,
    START_GAME = 386,

    // Volleyball
    SERVICE = 62,
    RALLY = 63,
    POINT = 64,
    LAST_X_POINTS = 65,
    FAULT = 66,
    ACE = 67,
    END_OF_SET = 68,
    TECHNICAL_TIME_OUT = 69,
    BREAKDOWN = 70,
    CURRENT_STREAK = 71,
    GOLDEN_SET = 72,
    STANDBY_FOR_SERVE = 328,
    TIME_OUT_OVER = 329,
    BLOCK = 330,
    ERROR = 331,
    OUT = 332,

    // Rugby union
    TRY = 73,
    KICK_AWARDED = 74,
    CONVERSION_SCORED = 75,
    CONVERSION_MISSED = 76,
    PENALTY_SCORED = 77,
    SIN_BIN = 78,
    SIN_BIN_RETURN = 79,
    DROP = 80,
    SCRUM = 81,
    LINE_OUT = 82,
    LINE_OUT_WON = 342,
    TERRITORIAL_PLAY = 83,
    NO_TRY = 84,
    SUDDEN_DEATH = 85,
    END_OF_SUDDEN_DEATH = 86,
    PENALTY_AWARDED = 87,
    KICK_TO_TOUCH = 88,
    KICK_DOWNFIELD = 89,
    PENALTY_ADVANTAGE = 90,
    TAP_AND_GO_BY = 91,
    SAFE_FREE_KICK = 333,
    PENALTY_POINTS = 334,
    PENALTY_TRY = 335,
    TWENTYTWO_DROP_OUT = 336,
    SCRUM_OUTCOME = 337,
    SCRUM_RESET = 338,
    MAUL = 339,

    // Rugby league
    CONVERSION_ATTEMPT = 92,
    TACKLE_COUNT = 93,
    DROP_OUT = 94,
    GOLDEN_POINT = 95,
    TWENTY_METRE_RESTART = 576,
    LINE_BREAK = 577,
    KICK_AT_GOAL_ATTEMPT = 578,

    // Cricket
    RUNS_SCORED = 96,
    BYE = 97,
    LEG_BYE = 98,
    WIDE = 99,
    NO_BALL = 100,
    WICKET_SCORED = 101,
    BOWLED = 102,
    LBW = 103,
    RUN_OUT = 104,
    CAUGHT = 105,
    STUMPED = 106,
    RETIRED_HURT = 107,
    HIT_WICKET = 108,
    HANDLED_BALL = 109,
    HIT_BALL_TWICE = 110,
    OBSTRUCTING = 111,
    TIMED_OUT = 112,
    RETIRED_OUT = 113,
    RAIN_DELAY = 114,
    HAT_TRICK_BALL = 115,
    STAT_ADJUSTMENT = 116,
    FIRST_INNINGS = 117,
    SECOND_INNINGS = 118,
    POWER_PLAY = 119,
    THIRD_UMPIRE = 120,
    THIRD_UMPIRE_NOT_OUT = 121,
    REVIEW = 122,
    REVIEW_SUCCESSFUL = 123,
    REVIEW_UNSUCCESSFUL = 124,
    FREE_HIT = 125,
    SUPER_OVER = 126,
    BAD_LIGHT = 127,
    DUCK = 128,
    GOLDEN_DUCK = 129,
    LANDMARK = 130,
    CENTURY = 131,
    BOWLING = 132,
    END_OF_OVER = 133,
    LUNCH = 134,
    TEA = 135,
    PITCH_INSPECTION = 136,
    MAIDEN_OVER = 137,
    INNINGS_BREAK = 138,

    // Ice Hockey
    PENALTY_TIME = 139,
    POWER_PLAY_OVER = 140,
    SHOT = 141,
    PENALTY_SHOT = 142,
    PULLED_KEEPER = 143,
    KEEPER_BACK = 144,
    FACE_OFF = 145,
    PUCK_DROPPED = 146,
    FACE_OFF_WINNER = 147,
    ICING = 148,
    PENALTY_OVER = 149,
    START_OF_PERIOD1 = 313,
    END_OF_PERIOD1 = 314,
    START_OF_PERIOD2 = 315,
    END_OF_PERIOD2 = 316,
    START_OF_PERIOD3 = 317,
    END_OF_PERIOD3 = 318,
    VIDEO_REVIEW_START = 340,
    VIDEO_REVIEW_OVER = 341,
    SUSPENDED_SERIOUS_INJURY = 343,
    TIME_STOPPED = 344,
    MATCH_IS_IN_PROGRESS = 345,
    GOALTENDER_IS_ON_THE_ICE = 346,
    GOALKEEPER_OFF = 347,
    HIT = 348,
    MATCH_START_DELAYED = 349,
    BALL_SAFE = 350,

    // Tennis
    PLAYER_ON_SERVE = 150,
    DOUBLE_FAULT = 151,
    BREAK_POINT = 152,
    GAME = 153,
    END_OF_GAME = 154,
    LET_SERVE1 = 155,
    LET_SERVE2 = 156,
    CHALLENGE = 157,
    CHALLENGE_SUCCESS = 158,
    CHALLENGE_FAILED = 159,
    START_OF_SET1 = 160,
    START_OF_SET2 = 161,
    START_OF_SET3 = 162,
    START_OF_SET4 = 163,
    START_OF_FINAL_SET = 164,
    MATCH_STARTED = 165,

    // Water polo
    OVER_TIME1 = 166,
    SUSPENDED = 167,
    END_OF_QUARTER1 = 168,
    END_OF_QUARTER2 = 169,
    END_OF_QUARTER3 = 170,
    END_OF_QUARTER4 = 171,
    END_OF_OVER_TIME = 172,
    END_OF_PERIOD_OVER_TIME = 173,
    PENALTY_SHOOT_OUT_LOWER = 174,
    OVER_TIME2 = 175,

    // Handball
    THROW_OFF = 176,
    END_OF_HALF1 = 177,
    END_OF_HALF2 = 178,
    HALF_2_BOTTOM = 179,
    THROW_OFF_OVER_TIME_BOTTOM = 180,
    OVER_TIME_HALF_2_BOTTOM = 181,
    WITHOUT_GOALKEEPER = 352,
    INTERCEPT = 353,

    // American football
    TOUCH_DOWN = 182,
    FIELD_GOAL = 183,
    PAT_ATTEMPT = 184,
    CONVERSION_PAT_ATTEMPT = 185,
    PAT_SCORED = 186,
    PAT_BLOCKED = 187,
    PAT_FAIL = 188,
    PAT_FUMBLED = 189,
    PAT_INTERCEPTION_ANIM = 190,
    PAT_MADE = 191,
    CONVERSION_BLOCKED = 192,
    PAT_CONVERSION_FAIL = 193,
    PAT_CONVERSION_FUMBLED = 194,
    PAT_INTERCEPTION = 195,
    SAFETY = 196,
    TURNOVER = 197,
    TURNOVER_INTERCEPTION = 198,
    LOST_FUMBLE = 199,
    FUMBLE = 200,
    FLAG_ON_PLAY = 201,
    PICKED_UP = 202,
    PENALTIES_OFFSET = 203,
    PENALTY_AFTER_TOUCH_DOWN = 204,
    PENALTY_DECLINED = 205,
    SPOT_FOUL = 206,
    THIRD_AND_LONG = 207,
    PUNT_RETURNED = 208,
    FAIR_CATCH = 209,
    PUNT_TOUCH_BACK = 210,
    PUNT_DOWNED = 211,
    PUNT_OUT_OF_BOUNDS = 212,
    PUNT_BLOCKED = 213,
    PUNT_FAKE = 214,
    KICK_OFF_RETURNED = 215,
    FAIR_CATCH_OTHER = 216,
    KICK_OFF_TOUCH_BACK = 217,
    KICK_OFF_DOWNED = 218,
    KICK_OFF_OUT_OF_BOUNDS = 219,
    ONSIDE_KICK = 220,
    BACK_SACK = 221,
    TWO_MINUTE_WARNING = 222,
    COACHES_CHALLENGE = 223,
    TV_TIME_OUT = 224,
    OFFICAL_REVIEW = 225,
    WEATHER_DELAY = 226,
    DELAY = 227,
    FIELD_GOAL_MISSED = 228,
    FAKE_FIELD_GOAL = 229,
    FIELD_GOAL_BLOCKED = 230,
    PASS = 231,
    RUSH_OUTCOME = 232,
    PASS_INCOMPLETE = 233,
    RUSH_FAILED = 234,
    COACHES_CHALLENGE_CALL_STANDS = 235,
    CHALLENGE_OVERTURNED = 236,
    PUNT = 237,
    PLAY_START = 238,
    PLAY_OVER = 239,
    ANIM_PENALTY_ACCEPT = 240,
    ANIM_DEFENSIVE_RETURNS = 241,
    ANIM_KICK_OFF_MODE = 242,
    ANIM_VIDEO_REVIEW_START = 243,
    ANIM_VIDEO_REVIEW_OVER_CALL_STANDS = 244,
    ANIM_VIDEO_REVIEW_OVER_CALL_OVERTURNED = 245,
    REPLAY_PASS = 246,
    REPLAY_RUSH = 247,
    REPLAY_PUNT = 248,
    REPLAY_TOUCH_BACK = 249,
    REPLAY_PASS_INCOMPLETE = 250,
    REPLAY_FUMBLE = 251,
    REPLAY_INTERCEPTION = 252,
    REPLAY_TURNOVER_ON_DOWNS = 253,
    REPLAY_SAFETY = 254,
    REPLAY_TOUCH_DOWN = 255,
    REPLAY_FIELD_GOAL_SCORED = 256,
    REPLAY_PAT_SCORED = 257,
    REPLAY_TWO_PAT_CONVERSION = 258,
    REPLAY_FIELD_GOAL_MISSED = 259,
    FIELD_GOAL_ATTEMPT = 260,

    // Baseball module
    START_INNINGS = 261,
    BALL_IN_PLAY = 262,
    SINGLE = 263,
    DOUBLE = 264,
    TRIPLE = 265,
    HOME_RUN = 266,
    RUN_SCORED = 267,
    BALL = 268,
    STRIKE = 269,
    FOUL_SHOT = 270,
    DOUBLE_PLAY = 271,
    TRIPLE_PLAY = 272,
    HIT_BY_PITCH = 273,
    SACRIFICE_FLY = 274,
    PITCH_OUTCOME = 275,
    OUT_SCORED = 276,
    END_OF_HALF_INNINGS = 277,
    WALK = 278,
    INTENTIONAL_WALK = 279,
    STEAL = 280,
    CAUGHT_STEALING = 281,
    REACH_ON_ERROR = 282,
    REACH_ON_FIELDERS = 283,
    NEW_BATTER = 284,
    NEW_PITCHER = 285,
    STRIKE_OUT = 286,
    RAIN_DELAY_OVER = 287,
    MOVE = 354,

    // Darts
    THROW = 288,
    TOTAL_SCORE = 289,
    DOUBLE_IN_MISS = 290,
    BUST = 291,
    GAME_SHOT = 292,
    NINE_DART_FINISH = 293,

    // Horse racing
    GOING_DOWN = 294,
    GOING_BEHIND = 295,
    RACE_OFF = 296,
    FURLONG = 297,
    JUMP = 298,
    PHOTO_FINISH = 299,
    END_OF_RACE = 300,
    RACE_RESULT = 301,
    RACE_IN_PROGRESS = 302,
    FALSE_START = 303,
    GPS_FEED_ERROR = 304,
    GPS_FEED_RESUME = 305,

    // Aussie rules
    RUSHED_BEHIND = 306,
    BEHIND = 307,
    CENTER_BOUNCE = 308,
    FREE_KICK = 309,
    OUT_OF_BOUNDS = 310,
    THROW_IN = 311,
    MARK = 312,

    // Snooker
    RERACK = 355,
    FRAME = 356,
    UNSCHEDULED_BREAK = 357,
    REMOVE_BALL = 358,
    FREE_BALL_AWARDED = 359,
    BLUE_BALL = 360,
    RED_BALL = 361,
    PINK_BALL = 362,
    GREEN_BALL = 363,
    YELLOW_BALL = 364,
    BROWN_BALL = 365,
    BLACK_BALL = 366,

    // Beach Volleyball
    SERVE_ACE = 367,
    SERVE_FAULT = 368,

    // Futsal
    SIX_METER_PENALTY = 369,
    TEN_METER_PENALTY = 370,

    // Baseball ++
    START_OF_INNING1 = 371,
    START_OF_INNING2 = 372,
    START_OF_INNING3 = 373,
    START_OF_INNING4 = 374,
    START_OF_INNING5 = 375,
    START_OF_INNING6 = 376,
    START_OF_INNING7 = 377,
    START_OF_INNING8 = 378,
    START_OF_INNING9 = 379,
    START_OF_INNING10 = 380,
    END_OF_INNING = 381,
    OUT_CANCELLED = 382,
    BALL_CANCELLED = 383,
    STRIKE_CANCELLED = 384,
    PITCHER_CHANGE_CANCELLED = 385,
}
