import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GameEvent, GameEventTypeUnion } from '../../models';
import { ResponseMessageInterface } from '../../models/response-message.interface';
import { TimerInterface } from '../../models/proto-models/interfaces/timer.interface';
import { TimerFormatMinutePipe } from '../../match-visualization/pipes';
import { StatItemInterface } from '../../models/proto-models/interfaces/stat-item.interface';
import { PropertyInterface } from '../../models/proto-models/interfaces/property.interface';
import { formatDate } from '@angular/common';
import { GameEventType } from '../../models/proto-models/enums/game-event-type.enum';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {

    private currentEnvironment = environment;

    public logMsg(res: ResponseMessageInterface): void {
        if (this.currentEnvironment.logger === false) {
            return;
        }

        if (res && res.gameEvent) {
            this.logEvent('GameEvent from BE', res.gameEvent);

            return;
        }
        if (res && res.timer) {
            this.logTimer('Timer update from BE', res.timer);

            return;
        }
        if (res && res.stat) {
            this.logStatItem('Stat update from BE', res.stat);

            return;
        }
        if (res && res.property) {
            this.logProperty('Property update from BE', res.property);

            return;
        }
        if (res && res.subOk) {
            this.logSnapshot(res);

            return;
        }

        console.log('Smth else:', res);
    }

    public logEvent(msg: string, event: GameEvent<GameEventTypeUnion>): void {
        if (this.currentEnvironment.logger === false) {
            return;
        }

        console.log(
            `%c ${msg} (${GameEventType[event.type]})`,
            'color: red; font-size: 16px',
            event,
        );
        console.table(event);
    }

    public logTimer(msg: string, timer: TimerInterface): void {
        if (this.currentEnvironment.logger === false) {
            return;
        }

        console.log(
            `%c ${msg}`,
            'color: pink; font-size: 16px',
            `Formatted value: ${new TimerFormatMinutePipe().transform(timer.value)}`,
            timer,
        );
    }

    public logStatItem(msg: string, statItem: StatItemInterface): void {
        if (this.currentEnvironment.logger === false) {
            return;
        }

        console.log(
            `%c ${msg}`,
            'color: blue; font-size: 16px',
            statItem,
        );
    }

    public logProperty(msg: string, property: PropertyInterface): void {
        if (this.currentEnvironment.logger === false) {
            return;
        }

        console.log(
            `%c ${msg}`,
            'color: orange; font-size: 16px',
            property,
        );
    }

    public logSnapshot(event: ResponseMessageInterface): void {
        if (this.currentEnvironment.logger === false) {
            return;
        }

        console.log('%c Snapshot Data starts', 'color: green; font-size: 16px');
        if (event.subOk && event.subOk.stateList) {
            event.subOk.stateList.forEach(s => {
                Object.keys(s).filter(k => !!s[k]).forEach(n => {
                    console.log(n, s[n]);
                });
            });
        }
        console.log('%c Snapshot Data ends', 'color: green; font-size: 16px');
    }

    public logPing(msg: string, event: ResponseMessageInterface): void {
        if (this.currentEnvironment.logger === false) {
            return;
        }

        console.log(
            `%c ${msg}`,
            'color: black; font-size: 16px',
            event,
        );
    }

    public logTimestamp(): void {
        if (this.currentEnvironment.logger === false) {
            return;
        }

        console.log(`%c --- timestamp: ${formatDate(Date.now(), 'HH:mm:ss', 'en')} ---`, 'margin: 20px 0; text-align: center');
    }

    public logData(data: any, msg?: string): void {
        if (this.currentEnvironment.logger === false) {
            return;
        }

        console.log(`${msg || 'Some data: '}`, data);
    }
}
